import {
  FETCH_INTAKE_FORM,
  ADD_INTAKE_FORM_GOAL,
  ADD_INTAKE_FORM_PROFILE,
  ADD_INTAKE_FORM_ADVISING,
  ADD_INTAKE_FORM_COMPLETE,
  ADD_INTAKE_FORM_PASSPORT,
  ADD_INTAKE_FORM_FINANCIAL_AID,
  ADD_INTAKE_FORM_TRAVELER_TYPE,
  ADD_INTAKE_FORM_FINANCIAL_INFO,
  ADD_INTAKE_FORM_HOME_INSTITUTION,
  ADD_INTAKE_FORM_TRAVEL_EXPERIENCE,
  ADD_INTAKE_FORM_PARTICIPATION_CERTAINTY,
  ADD_INTAKE_FORM_COUNTRIES_OF_CITIZENSHIP,
} from '../actions/types';

const initialState = {
  advising: [],
  readonly: [],
  last_name: '',
  first_name: '',
  school_name: '',
  signup_date: '',
  travel_goals: [],
  traveler_type: '',
  signup_source: '',
  financial_info: [],
  ssoTraveler: false,
  intake_complete: null,
  travel_experience: [],
  travel_certainty: null,
  countries_of_citizenship: [],
  receives_federal_financial_aid: null,
  passport: { status: null, alpha2: null },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_INTAKE_FORM:
      return {
        ...state,
        advising: action.payload.advising,
        passport: action.payload.passport,
        readonly: action.payload.readonly,
        last_name: action.payload.last_name,
        first_name: action.payload.first_name,
        school_name: action.payload.school_name,
        signup_date: action.payload.signup_date,
        ssoTraveler: action.payload.sso_traveler,
        travel_goals: action.payload.travel_goals,
        signup_source: action.payload.signup_source,
        traveler_type: action.payload.traveler_type,
        financial_info: action.payload.financial_info,
        travel_certainty: action.payload.travel_certainty,
        travel_experience: action.payload.travel_experience,
        countries_of_citizenship: action.payload.countries_of_citizenship,
        receives_federal_financial_aid: action.payload.receives_federal_financial_aid,
      };
    case ADD_INTAKE_FORM_PROFILE:
      return {
        ...state,
        last_name: action.payload.last_name,
        first_name: action.payload.first_name,
      };
    case ADD_INTAKE_FORM_COUNTRIES_OF_CITIZENSHIP:
      return {
        ...state,
        countries_of_citizenship: action.payload.countries_of_citizenship,
      };
    case ADD_INTAKE_FORM_TRAVELER_TYPE:
      return {
        ...state,
        traveler_type: action.payload,
      };
    case ADD_INTAKE_FORM_HOME_INSTITUTION:
      return {
        ...state,
        school_name: action.payload.school_name,
      };
    case ADD_INTAKE_FORM_ADVISING:
      return {
        ...state,
        advising: action.payload,
      };
    case ADD_INTAKE_FORM_FINANCIAL_INFO:
      return {
        ...state,
        financial_info: action.payload,
      };
    case ADD_INTAKE_FORM_FINANCIAL_AID:
      return {
        ...state,
        receives_federal_financial_aid: action.payload,
      };
    case ADD_INTAKE_FORM_GOAL:
      return {
        ...state,
        travel_goals: action.payload,
      };
    case ADD_INTAKE_FORM_TRAVEL_EXPERIENCE:
      return {
        ...state,
        travel_experience: action.payload,
      };
    case ADD_INTAKE_FORM_PASSPORT:
      return {
        ...state,
        passport: action.payload,
      };
    case ADD_INTAKE_FORM_PARTICIPATION_CERTAINTY:
      return {
        ...state,
        intake_complete: action.payload.intake_complete,
        travel_certainty: action.payload.travel_certainty,
      };
    case ADD_INTAKE_FORM_COMPLETE:
      return {
        ...state,
        intake_complete: action.payload,
      };

    default:
      return state;
  }
}
