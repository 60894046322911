import {
  CREATE_NEW_PROGRAM_CLEAR,
  CREATE_NEW_PROGRAM_REQUEST,
  CREATE_NEW_PROGRAM_SUCCESS,
  CREATE_NEW_PROGRAM_FAILURE,
  DELETE_DRAFT_PROGRAM_REQUEST,
  DELETE_DRAFT_PROGRAM_SUCCESS,
  DELETE_DRAFT_PROGRAM_FAILURE,
  GET_PROGRAM_REQUEST,
  GET_PROGRAM_SUCCESS,
  GET_PROGRAM_FAILURE,
  MODIFY_PROGRAM_CLEAR,
  MODIFY_PROGRAM_REQUEST,
  MODIFY_PROGRAM_SUCCESS,
  MODIFY_PROGRAM_FAILURE,
} from '../actions/types';

const initialState = {
  programCreate: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
  },
  programDeleteDraft: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
    success: false,
  },
  programGet: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
  },
  programModify: {
    data: null,
    error: null,
    errorMsg: '',
    loading: false,
    success: false,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CREATE_NEW_PROGRAM_CLEAR:
      return {
        ...state,
        programCreate: {
          data: null,
          error: null,
          errorMsg: '',
          loading: false,
        },
      };
    case CREATE_NEW_PROGRAM_REQUEST:
      return {
        ...state,
        programCreate: {
          data: null,
          error: null,
          errorMsg: '',
          loading: true,
        },
      };
    case CREATE_NEW_PROGRAM_SUCCESS:
      return {
        ...state,
        programCreate: {
          data: action.payload.data,
          error: false,
          errorMsg: '',
          loading: false,
        },
      };
    case CREATE_NEW_PROGRAM_FAILURE:
      return {
        ...state,
        programCreate: {
          data: null,
          error: true,
          errorMsg: 'Error creating new program.',
          loading: false,
        },
      };
    case DELETE_DRAFT_PROGRAM_REQUEST:
      return {
        ...state,
        programDeleteDraft: {
          data: null,
          error: null,
          errorMsg: '',
          loading: true,
          success: false,
        },
      };
    case DELETE_DRAFT_PROGRAM_SUCCESS:
      return {
        ...state,
        programDeleteDraft: {
          data: null,
          error: false,
          errorMsg: '',
          loading: false,
          success: true,
        },
      };
    case DELETE_DRAFT_PROGRAM_FAILURE:
      return {
        ...state,
        programDeleteDraft: {
          data: null,
          error: true,
          errorMsg: 'There was a problem deleting...',
          loading: false,
          success: false,
        },
      };
    case GET_PROGRAM_REQUEST:
      return {
        ...state,
        programGet: {
          data: null,
          error: null,
          errorMsg: '',
          loading: true,
        },
      };
    case GET_PROGRAM_SUCCESS:
      return {
        ...state,
        programGet: {
          data: action.payload.data,
          error: false,
          errorMsg: '',
          loading: false,
        },
      };
    case GET_PROGRAM_FAILURE:
      return {
        ...state,
        programGet: {
          data: null,
          error: true,
          errorMsg: 'There was and error loading....',
          loading: false,
        },
      };
    case MODIFY_PROGRAM_CLEAR:
      return {
        ...state,
        programModify: {
          data: null,
          error: null,
          errorMsg: '',
          loading: false,
          success: false,
        },
      };
    case MODIFY_PROGRAM_REQUEST:
      return {
        ...state,
        programModify: {
          data: null,
          error: null,
          errorMsg: '',
          loading: true,
          success: false,
        },
      };
    case MODIFY_PROGRAM_SUCCESS:
      return {
        ...state,
        programModify: {
          data: action.payload.data,
          error: null,
          errorMsg: '',
          loading: false,
          success: true,
        },
      };
    case MODIFY_PROGRAM_FAILURE:
      return {
        ...state,
        programModify: {
          data: null,
          error: true,
          errorMsg: '',
          loading: false,
          success: false,
        },
      };
    default:
      return state;
  }
}
