import {
  GET_CURRENT_USER,
  GET_RACE_OPTIONS,
  UPDATE_TRAVELER_INFO,
  GET_GENDER_IDENTITIES,
  GET_NOTIFICATION_TYPES,
  GET_NOTIFICATION_OPT_OUTS,
  GET_TRAVELER_PROFILE_DATA,
} from '../actions/types';

const initialState = {
  role: '',
  info: null,
  readonly: [],
  profile: null,
  customFields: null,
  freemium: false,
  raceOptions: [],
  showMatch: null,
  travelerEmail: '',
  notifications: [],
  adminAccess: false,
  adminSignIn: false,
  matchComplete: null,
  genderIdentities: [],
  passportStatus: null,
  currentUserloading: true,
  archivedNotifications: [],
  travelerProfileLoading: true,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CURRENT_USER:
      return {
        ...state,
        currentUserLoading: false,
        role: action.payload.role,
        freemium: action.payload.freemium,
        adminAccess: action.payload.admin_access,
        adminSignIn: action.payload.admin_sign_in,
        showMatch: action.payload.show_program_match,
        matchComplete: action.payload.program_match_complete,
        showIntake: action.payload.show_intake,
        intakeComplete: action.payload.intake_complete,
      };
    case GET_TRAVELER_PROFILE_DATA:
      return {
        ...state,
        info: action.payload.info,
        travelerProfileLoading: false,
        profile: action.payload.profile,
        customFields: action.payload.custom_fields,
        readonly: action.payload.readonly,
        travelerEmail: action.payload.traveler_email,
        passportStatus: action.payload.passport_status,
      };
    case GET_GENDER_IDENTITIES:
      return {
        ...state,
        genderIdentities: action.payload,
      };
    case GET_RACE_OPTIONS:
      return {
        ...state,
        raceOptions: action.payload,
      };
    case UPDATE_TRAVELER_INFO:
      return {
        ...state,
        profile: {
          ...state.profile,
          avatar: action.payload.avatar,
          last_name: action.payload.last_name,
          first_name: action.payload.first_name,
        },
      };
    case GET_NOTIFICATION_TYPES:
      return {
        ...state,
        notifications: action.payload,
      };
    case GET_NOTIFICATION_OPT_OUTS:
      return {
        ...state,
        archivedNotifications: action.payload,
      };
    default:
      return state;
  }
}
