/* eslint-disable global-require */
// The top-level (parent) route
const routes = {
  path: '',

  // Keep in mind, routes are evaluated in order
  children: [
    // The home route is added to client.js to make sure shared components are
    // added to client.js as well and not repeated in each individual route chunk.
    {
      path: '/intake',
      // load: () => import(/* webpackChunkName: 'intake' */ './intake'),
      action(context) {
        return {
          redirect: '/traveler/intake',
        }; // <== request a redirect
      },
    },
    {
      path: '/client/org-settings',
      load: () => import(/* webpackChunkName: 'org-settings' */ './org-settings'),
    },

    {
      path: '/client/reports',
      load: () => import(/* webpackChunkName: 'reports' */ './reports'),
    },
    {
      path: '/client',
      children: [
        {
          path: '/sign_in',
          action(context) {
            return {
              redirect: context.query.jmp ? `/?jmp=${context.query.jmp}` : '/',
            }; // <== request a redirect
          },
          // load: () =>
          //   import(/* webpackChunkName: 'admin-sign-in' */ './admin-sign-in'),
        },
        {
          path: '/non_sso_sign_in',
          load: () =>
            import(
              /* webpackChunkName: 'authV2-welcome-email' */ '../sites/authentication/pages/welcomeSSO-email/WelcomeSSOEmail.page'
            ),
          // load: () => import(/* webpackChunkName: 'admin-sign-in' */ './admin-sign-in'),
        },
        {
          path: '/travelers',
          action(context) {
            return {
              redirect: '/client/traveler-dashboard',
            }; // <== request a redirect
          },
          // load: () => import(/* webpackChunkName: 'admin-traveler-list' */ './admin-traveler-list'),
        },
        {
          path: '/travelers/:id',
          load: () => import(/* webpackChunkName: 'admin-traveler-view' */ './admin-traveler-view'),
        },
        {
          path: '/travelers/:id/view-programs/:tab?',
          load: () => import(/* webpackChunkName: 'admin-traveler-view' */ './admin-traveler-view'),
        },
        {
          path: '/travelers/:id/view-programs/details/:submissionId',
          load: () => import(/* webpackChunkName: 'program-details-view' */ './admin-program-details-view'),
        },

        {
          path: '/automations',
          action(context) {
            return {
              redirect: '/client/automation-dashboard',
            }; // <== request a redirect
          },
          // load: () => import(/* webpackChunkName: 'admin-traveler-list' */ './admin-traveler-list'),
        },

        // {
        //   path: '/automations/create',
        //   load: () => import(/* webpackChunkName: 'admin-traveler-view-automation' */ './admin-automation-view'),
        // },

        {
          path: '/automations/edit/:id',
          load: () => import(/* webpackChunkName: 'admin-traveler-view-automation' */ './admin-automation-view'),
        },

        {
          path: '/programs/:program_id/edit',

          // load: () => import(/* webpackChunkName: 'admin-program-view-edit' */ './admin-program-view-edit'),
          action(context) {
            return {
              redirect: `/client/programs/${context.params.program_id}/modify`,
            }; // <== request a redirect
          },
        },
        {
          path: '/programs/:program_id/modify',
          load: () => import(/* webpackChunkName: 'admin-program-modify' */ '../sites/viaGlobal/pages/programModify'),
        },
        {
          path: '/programs/:program_id/program_settings',
          load: () => import(/* webpackChunkName: 'admin-program-settings' */ './admin-program-settings'),
        },
      ],
    },
    {
      path: '/program_brochure/:program_id/apply',
      load: () => import(/* webpackChunkName: 'program-brochure-apply' */ '../sites/programs/pages/apply'),
    },
    {
      path: '/program_brochure/:program_id/:tab?',
      load: () => import(/* webpackChunkName: 'program-brochure' */ '../sites/programs/pages'),
    },
    {
      path: '/client/program_brochure/:program_id/:tab?',
      load: () => import(/* webpackChunkName: 'admin-program-brochure' */ '../sites/programs/pages/adminBrochure'),
    },
    {
      path: '/traveler/intake',
      load: () => import(/* webpackChunkName: 'traveler-intake-form' */ '../sites/travelerForms/pages/intakeForm'),
    },
    {
      path: '/traveler/intake/steps/:step',
      load: () => import(/* webpackChunkName: 'traveler-intake-form-step' */ '../sites/travelerForms/pages/intakeForm'),
    },
    {
      path: '/traveler/intake/processing',
      load: () =>
        import(
          /* webpackChunkName: 'traveler-intake-form-processing' */ '../sites/travelerForms/pages/intakeProcessing'
        ),
    },
    {
      path: '/traveler/program-match',
      load: () =>
        import(/* webpackChunkName: 'traveler-program-match-form' */ '../sites/travelerForms/pages/programMatchForm'),
    },
    {
      path: '/traveler/program-match/steps/:step',
      load: () =>
        import(
          /* webpackChunkName: 'traveler-program-match-form-step' */ '../sites/travelerForms/pages/programMatchForm'
        ),
    },
    {
      path: '/traveler/program-match/processing',
      load: () =>
        import(
          /* webpackChunkName: 'traveler-program-match-form-processing' */ '../sites/travelerForms/pages/programMatchProcessing'
        ),
    },
    {
      path: '/traveler/profile/:tab?',
      load: () => import(/* webpackChunkName: 'traveler-profile' */ '../sites/travelerProfile/pages/index'),
    },
    {
      path: '/traveler/traveler-settings/:tab?',
      load: () => import(/* webpackChunkName: 'traveler-profile-settings' */ '../sites/travelerProfile/pages/settings'),
    },
    {
      path: '/traveler/events/:tab?',
      load: () => import(/* webpackChunkName: 'traveler-events' */ '../sites/travelerProfile/pages/events'),
    },
    {
      path: '/traveler/eventdetail/:id',
      load: () => import(/* webpackChunkName: 'traveler-events-detail' */ '../sites/travelerProfile/pages/eventdetail'),
    },
    {
      path: '/traveler/messages',
      load: () => import(/* webpackChunkName: 'traveler-messages' */ '../sites/viaGlobal/pages/travelerMessage'),
    },
    {
      path: '/traveler/safecheck',
      load: () => import(/* webpackChunkName: 'traveler-safecheck' */ '../sites/viaGlobal/pages/safeCheck'),
    },

    {
      path: '/traveler/create-solo-plan',
      load: () =>
        import(/* webpackChunkName: 'traveler-solo-plan-form' */ '../sites/viaGlobal/pages/travelerSoloPlanForm'),
    },

    {
      path: '/traveler/program-dashboard-v2',
      load: () =>
        import(
          /* webpackChunkName: 'traveler-program-dashboard-v2' */ '../sites/travelerProfile/pages/program-dashboard'
        ),
    },
    {
      path: '/visitor-programs-v2',
      load: () =>
        import(
          /* webpackChunkName: 'visitor-program-dashboard-v2' */ '../sites/travelerProfile/pages/visitor-programs'
        ),
    },
    {
      path: '/traveler/my-programs/details/:submissionId',
      load: () =>
        import(
          /* webpackChunkName: 'traveler-my-programs-details-v2' */ '../sites/travelerProfile/pages/my-programs-details'
        ),
    },
    {
      path: '/traveler/application/:submissionId',
      load: () =>
        import(
          /* webpackChunkName: 'traveler-application-details' */ '../sites/travelerProfile/pages/application-details'
        ),
    },
    {
      path: '/traveler/application/:submissionId/alternates',
      load: () =>
        import(
          /* webpackChunkName: 'traveler-application-alternates' */ '../sites/travelerProfile/pages/application-alternates'
        ),
    },
    {
      path: '/traveler/application/:submissionId/preview',
      load: () =>
        import(
          /* webpackChunkName: 'traveler-application-preview' */ '../sites/travelerProfile/pages/application-preview'
        ),
    },
    {
      path: '/traveler/application/:submissionId/success',
      load: () =>
        import(
          /* webpackChunkName: 'traveler-application-details-success' */ '../sites/travelerProfile/pages/application-success'
        ),
    },
    {
      path: '/traveler/application/:submissionId/form/:formId',
      load: () =>
        import(/* webpackChunkName: 'traveler-form-details-v2' */ '../sites/travelerProfile/pages/form-details'),
    },
    {
      path: '/traveler/application/:submissionId/form/:formId/success',
      load: () =>
        import(/* webpackChunkName: 'traveler-form-success-v2' */ '../sites/travelerProfile/pages/form-success'),
    },
    {
      path: '/plans/traveler-itinerary/:planId/:planUserId',
      load: () => import(/* webpackChunkName: 'plans-traveler-itinerary' */ '../sites/plans/pages/travelerItinerary'),
    },
    {
      path: '/plans/leader-traveler-itinerary/:planId/:planUserId',
      load: () =>
        import(
          /* webpackChunkName: 'plans-leader-traveler-itinerary' */ '../sites/plans/pages/leaderTravelerItinerary'
        ),
    },
    {
      path: '/client/alert-detail/:id',
      load: () => import(/* webpackChunkName: 'alert-detail-by-id' */ '../sites/viaGlobal/pages/alertDetail'),
    },

    {
      path: '/client/alert-detail-pdf',
      load: () => import(/* webpackChunkName: 'alert-detail-pdf' */ '../sites/viaGlobal/pages/alertDetailPDF'),
    },
    {
      path: '/client/application-dashboard',
      load: () =>
        import(
          /* webpackChunkName: 'client-admin-application-dashboard' */ '../sites/viaGlobal/components/pages/applicationDashboard/new/page'
        ),
    },
    {
      path: '/client/application-dashboard-v2',
      load: () =>
        import(
          /* webpackChunkName: 'client-admin-application-dashboard-v2' */ '../sites/viaGlobal/pages/applicationDashboardV2'
        ),
    },
    {
      path: '/client/event-dashboard',
      load: () => import(/* webpackChunkName: 'admin-event-dashboard' */ '../sites/viaGlobal/pages/events'),
    },
    {
      path: '/client/event-participant-dashboard',
      load: () =>
        import(
          /* webpackChunkName: 'admin-event-participants-dashboard' */ '../sites/viaGlobal/pages/eventParticipants'
        ),
    },
    {
      path: '/client/forms-status-dashboard',
      load: () =>
        import(
          /* webpackChunkName: 'admin-forms-status-dashboard-chunk' */ '../sites/viaGlobal/pages/formsStatusDashboard'
        ),
    },
    {
      path: '/client/program-dashboard/internal',
      load: () =>
        import(/* webpackChunkName: 'admin-program-dashboard-internal' */ '../sites/viaGlobal/pages/internalPrograms'),
    },
    {
      path: '/client/program-dashboard/authorized',
      load: () =>
        import(
          /* webpackChunkName: 'admin-program-dashboard-authorized' */ '../sites/viaGlobal/pages/authorizedPrograms'
        ),
    },
    {
      path: '/client/program-dashboard/terms',
      load: () =>
        import(/* webpackChunkName: 'admin-program-dashboard-terms' */ '../sites/viaGlobal/pages/programTerms'),
    },
    {
      path: '/client/program-dashboard/terms-authorized',
      load: () =>
        import(
          /* webpackChunkName: 'admin-program-dashboard-terms-authorized' */ '../sites/viaGlobal/pages/programTermsAuthorized'
        ),
    },
    {
      path: '/client/program-dashboard/unauthorized',
      load: () =>
        import(
          /* webpackChunkName: 'admin-program-dashboard-unauthorized' */ '../sites/viaGlobal/pages/unauthorizedPrograms'
        ),
    },
    {
      path: '/client/traveler-forms-dashboard',
      load: () =>
        import(
          /* webpackChunkName: 'admin-traveler-forms-dashboard-apply-chunk' */ '../sites/viaGlobal/components/pages/travelerFormsDashboard/new/page'
        ),
    },
    {
      path: '/client/form-content-dashboard',
      load: () =>
        import(
          /* webpackChunkName: 'admin-form-content-dashboard-chunk' */ '../sites/viaGlobal/pages/formContentDashboard'
        ),
    },
    {
      path: '/client/application-content-dashboard',
      load: () =>
        import(
          /* webpackChunkName: 'application-content-dashboard' */ '../sites/viaGlobal/pages/applicationContentDashboard'
        ),
    },
    {
      path: '/client/traveler-dashboard',
      load: () =>
        import(
          /* webpackChunkName: 'admin-traveler-dashboard-chunk' */ '../sites/viaGlobal/components/pages/travelerDashboard/new/page'
        ),
    },
    {
      path: '/client/dashboard',
      load: () =>
        import(/* webpackChunkName: 'admin-traveler-dashboard-chunk-new' */ '../sites/viaGlobal/pages/adminDashboard'),
    },
    {
      path: '/client/engagement_analytics',
      load: () =>
        import(
          /* webpackChunkName: 'engagement-analytics-dashboard-chunk' */ '../sites/viaGlobal/pages/engagementAnalyticsDashboard'
        ),
    },
    {
      path: '/client/analytics-reports',
      load: () =>
        import(
          /* webpackChunkName: 'engagement-analytics-reports-chunk' */ '../sites/viaGlobal/pages/engagementAnalyticsReports'
        ),
    },
    {
      path: '/client/organizations',
      load: () => import(/* webpackChunkName: 'organizations-chunk' */ '../sites/viaGlobal/pages/organizations'),
    },
    {
      path: '/client/organization/:id',
      load: () =>
        import(/* webpackChunkName: 'organization-detail-chunk' */ '../sites/viaGlobal/pages/organizationDetail'),
    },
    {
      path: '/client/automation-dashboard',
      load: () =>
        import(
          /* webpackChunkName: 'admin-automation-form-dashboard' */ '../sites/viaGlobal/pages/automationDashboard'
        ),
    },

    {
      path: '/client/automation-mailer-history/:id',
      load: () =>
        import(
          /* webpackChunkName: 'admin-automation-mailer-history' */ '../sites/viaGlobal/pages/automationMailerHIstory'
        ),
    },

    {
      path: '/client/automation-form-history/:id',
      load: () =>
        import(
          /* webpackChunkName: 'admin-automation-form-history' */ '../sites/viaGlobal/pages/automationFormHistory'
        ),
    },

    {
      path: '/client/automation-tag-history/:id',
      load: () =>
        import(/* webpackChunkName: 'admin-automation-form-history' */ '../sites/viaGlobal/pages/automationTagHistory'),
    },

    {
      path: '/client/automation-status-history/:id',
      load: () =>
        import(
          /* webpackChunkName: 'admin-automation-status-history' */ '../sites/viaGlobal/pages/automationChangeStatusHistory'
        ),
    },

    {
      path: '/program-match',
      // load: () => import(/* webpackChunkName: 'program-match' */ './program-match'),
      action(context) {
        return {
          redirect: '/traveler/program-match',
        }; // <== request a redirect
      },
    },
    {
      path: '/traveler/sign_in',
      action(context) {
        return {
          redirect: context.query.jmp ? `/?jmp=${context.query.jmp}` : '/',
        }; // <== request a redirect
      },
      //
      // load: () =>
      //   import(/* webpackChunkName: 'traveler-sign-in' */ './traveler-sign-in'),
    },
    {
      path: '/client/register/sign_in',
      action(context) {
        return {
          redirect: context.query.jmp ? `/?jmp=${context.query.jmp}` : '/',
        }; // <== request a redirect
      },
    },
    {
      path: '/traveler/user/sign_in',
      action(context) {
        return {
          redirect: context.query.jmp ? `/?jmp=${context.query.jmp}` : '/',
        }; // <== request a redirect
      },
    },
    {
      path: '/traveler/users/sign_in',
      action(context) {
        if (context?.query?.error_code?.length > 0 && context?.query?.message?.length > 0) {
          return {
            redirect: `/authV2/welcome/?error_code=${context?.query?.error_code}&message=${context?.query?.message}`,
          }; // <== request a redirect
        } else {
          return {
            redirect: context.query.jmp ? `/?jmp=${context.query.jmp}` : '/',
          }; // <== request a redirect
        }
      },
    },
    {
      path: '/traveler/non_sso_sign_in',
      load: () => import(/* webpackChunkName: 'traveler-sign-in' */ './traveler-sign-in'),
    },
    {
      path: '/traveler/sign_up',
      load: () => import(/* webpackChunkName: 'authV2-sign-up' */ '../sites/authentication/pages/signup/Signup.page'),
      // load: () => import(/* webpackChunkName: 'admin-sign-in' */ './traveler-sign-up'),
    },
    {
      path: '/traveler/settings',
      load: () => import(/* webpackChunkName: 'traveler-settings' */ './traveler-settings'),
    },
    {
      path: '/traveler/dashboard',
      load: () =>
        import(
          /* webpackChunkName: 'traveler-user-dashboard-chunk' */ '../sites/viaGlobal/pages/travelerUserDashboard'
        ),
    },
    {
      path: '/traveler/my-programs',
      load: () =>
        import(
          /* webpackChunkName: 'traveler-my-programs-dashboard-chunk' */ '../sites/viaGlobal/pages/travelerMyPrograms'
        ),
    },
    {
      path: '/forgot-password',
      load: () =>
        import(
          /* webpackChunkName: 'authV2-reset-password' */ '../sites/authentication/pages/reset-password/ResetPassword.page'
        ),
      // load: () => import(/* webpackChunkName: 'forgot-password' */ './forgot-password'),
    },
    {
      path: '/client/travel-plans',
      load: () => import(/* webpackChunkName: 'admin-travel-plans' */ './admin-travel-plans'),
    },
    {
      path: '/client/travel-plans/:id',
      load: () => import(/* webpackChunkName: 'admin-travel-plan-view' */ './admin-travel-plan-view'),
    },
    {
      path: '/client/events',
      action(context) {
        return {
          redirect: '/client/event-dashboard',
        }; // <== request a redirect
      },
      // load: () => import(/* webpackChunkName: 'admin-events' */ './admin-events'),
    },
    {
      path: '/client/events/create',
      load: () => import(/* webpackChunkName: 'event-create' */ './admin-event-create'),
    },
    {
      path: '/client/events/:id/edit',
      load: () => import(/* webpackChunkName: 'event-edit' */ './admin-event-edit'),
    },
    {
      path: '/client/events/:id',
      load: () => import(/* webpackChunkName: 'admin-event-view' */ './admin-event-view'),
    },
    {
      path: '/visitor/events/:id/same-day-signup',
      load: () =>
        import(
          /* webpackChunkName: 'visitor-same-day-event-signup' */ '../sites/visitor/pages/eventSameDayRegistration'
        ),
    },
    {
      path: '/traveler',
      // action(context) {
      //   return {
      //     redirect: '/traveler/dashboard',
      //   }; // <== request a redirect
      // },
      load: () => import(/* webpackChunkName: 'traveler-view' */ './traveler-view'),
    },
    {
      path: '/traveler/view-programs/:tab?',
      load: () => import(/* webpackChunkName: 'traveler-view' */ './traveler-view'),
    },
    {
      path: '/traveler/view-programs/details/:submissionId',
      load: () => import(/* webpackChunkName: 'traveler-program-details-view' */ './program-details-view'),
    },
    {
      path: '/client/programs',
      action(context) {
        return {
          redirect: '/client/program-dashboard/internal',
        }; // <== request a redirect
      },
    },
    {
      path: '/client/authorize-programs',
      load: () => import(/* webpackChunkName: 'admin-authorize-programs' */ './admin-authorize-programs'),
    },
    {
      path: '/client/applications',
      load: () => import(/* webpackChunkName: 'admin-applications' */ './admin-applications'),
    },
    {
      path: '/client/application_templates',
      // load: () => import(/* webpackChunkName: 'admin-applications' */ './application-templates'),
      action(context) {
        return {
          redirect: '/client/forms-dashboard',
        }; // <== request a redirect
      },
    },
    {
      path: '/client/form_templates',
      // load: () => import(/* webpackChunkName: 'admin-applications' */ './application-form'),
      action(context) {
        return {
          redirect: '/client/forms-dashboard',
        }; // <== request a redirect
      },
    },
    {
      path: '/client/application/:id/edit',
      load: () => import(/* webpackChunkName: 'admin-application-new' */ './admin-application-edit'),
    },
    {
      path: '/client/application_preview/:id',
      load: () => import(/* webpackChunkName: 'admin-application-preview' */ './admin-application-preview'),
    },
    {
      path: '/client/traveler_application/:id',
      // load: () => import(/* webpackChunkName: 'admin-traveler-application' */ './admin-traveler-application'),
      action(context) {
        return {
          redirect: '/client/application-dashboard',
        }; // <== request a redirect
      },
    },
    {
      path: '/client/application_dashboard',
      // load: () => import(/* webpackChunkName: 'admin-application-dashboard' */ './admin-application-dashboard'),
      action(context) {
        return {
          redirect: '/client/application-dashboard',
        }; // <== request a redirect
      },
    },
    {
      path: '/client/form_dashboard',
      load: () => import(/* webpackChunkName: 'client-form-dashboard' */ './admin-application-dashboard'),
    },
    {
      path: '/client/recommendation/:recommender_token',
      load: () => import(/* webpackChunkName: 'admin-recommendations' */ './admin-recommendations'),
    },
    {
      path: '/client/traveler_application/:id/transfer',
      // load: () => import(/* webpackChunkName: 'admin-application-transfer' */ './admin-application-transfer'),
      action(context) {
        return {
          redirect: '/client/application-dashboard',
        }; // <== request a redirect
      },
    },
    {
      path: '/client/forms',
      load: () => import(/* webpackChunkName: 'admin-forms' */ './admin-forms'),
    },
    {
      path: '/traveler/programs',
      action(context) {
        return {
          redirect: '/traveler/program-dashboard-v2',
        }; // <== request a redirect
      },
    },
    {
      path: '/traveler/form/:id',
      // load: () => import(/* webpackChunkName: 'traveler-form' */ './traveler-form'),
      action(context) {
        return {
          redirect: '/traveler?tab=programs',
        }; // <== request a redirect
      },
    },
    {
      path: '/traveler/form-submission/:id',
      load: () =>
        import(/* webpackChunkName: 'traveler-form-submission' */ '../sites/forms/pages/traveler/TravelerForm.page'),
    },
    {
      path: '/client/form/:id',
      // load: () => import(/* webpackChunkName: 'admin-traveler-form' */ './admin-traveler-form'),
      action(context) {
        return {
          redirect: '/client/traveler-forms-dashboard',
        }; // <== request a redirect
      },
    },
    {
      path: '/traveler/programs/:program_id',
      load: () => import(/* webpackChunkName: 'traveler-program-view' */ './traveler-program-view'),
    },
    {
      path: '/traveler/programs/:id/apply',
      // load: () => import(/* webpackChunkName: 'traveler-application' */ './traveler-application'),
      action(context) {
        return {
          redirect: '/traveler?tab=programs',
        }; // <== request a redirect
      },
    },
    {
      path: '/traveler/events/:id',
      load: () => import(/* webpackChunkName: 'traveler-event-view' */ './traveler-event-view'),
    },

    {
      path: '/traveler-alert-detail/:id',
      load: () => import(/* webpackChunkName: 'traveler-alert-detail' */ './traveler-alert-detail'),
    },

    {
      path: '/traveler/program-dashboard',
      action(context) {
        return {
          redirect: '/traveler/program-dashboard-v2',
        }; // <== request a redirect
      },
    },
    {
      path: '/visitor/events/:id',
      load: () => import(/* webpackChunkName: 'event-view-visitor' */ '../sites/visitor/pages/eventRegistration'),
    },
    {
      path: '/client/programs/new',
      load: () => import(/* webpackChunkName: 'admin-program-view-new' */ './admin-program-view-new'),
    },
    {
      path: '/client/programs/:program_id',
      load: () => import(/* webpackChunkName: 'admin-program-view' */ './admin-program-view'),
    },
    {
      path: '/visitor/programs/:program_id',
      load: () => import(/* webpackChunkName: 'visitor-program-view' */ './visitor-program-view'),
    },
    {
      path: '/visitor/recommendation/:recommender_token',
      //   load: () => import(/* webpackChunkName: 'visitor-recommendations' */ './visitor-recommendations'),
      action(context) {
        return {
          redirect: `/visitor/recommendation_submissions/${context.params.recommender_token}`,
        }; // <== request a redirect
      },
    },
    {
      path: '/visitor/set_password/:token',
      load: () =>
        import(
          /* webpackChunkName: 'authV2-change-password' */ '../sites/authentication/pages/change-password/ChangePassword.page'
        ),
      // load: () => import(/* webpackChunkName: 'visitor-recommendations' */ './visitor-set-password'),
    },
    {
      path: '/traveler/application/:application_id/programs_rank',
      load: () => import(/* webpackChunkName: 'traveler-program-rank' */ './traveler-program-rank'),
    },
    {
      path:
        '/visitor/masquerade/:token/:email/:role/:user_id/:client_id/:subdomain/:freemium/:lead/:legacy/:travel_plans',
      load: () => import(/* webpackChunkName: 'visitor-recommendations' */ './visitor-masquerade'),
    },
    {
      path: '/servererror',
      load: () => import(/* webpackChunkName: 'form-preview' */ './errorpages/ServerError'),
    },
    {
      path: '/access_denied',
      load: () => import(/* webpackChunkName: 'access-denied' */ './access-denied'),
    },
    {
      path: '/sso_errors',
      load: () => import(/* webpackChunkName: 'sso-errors' */ './sso-errors'),
    },
    {
      path: '/',
      load: () => import(/* webpackChunkName: 'authV2-started' */ '../sites/authentication/pages/started/Started.page'),
      // load: () => import(/* webpackChunkName: 'admin-sign-in' */ './admin-sign-in'),
    },
    {
      path: '/not_found',
      load: () => import(/* webpackChunkName: 'errorpages/NotFound' */ './errorpages/NotFound'),
    },
    {
      path: '/sites/style-guide',
      load: () => import(/* webpackChunkName: 'sites-style-guid' */ '../sites/components/pages/guide'),
    },
    {
      path: '/plans/my-travel',
      load: () => import(/* webpackChunkName: 'plans-my-travel' */ '../sites/plans/pages/myTravel'),
    },
    {
      path: '/plans/register-travel-plans',
      load: () =>
        import(/* webpackChunkName: 'plans-group-travel-register' */ '../sites/plans/pages/registerTravelPlans'),
    },
    {
      path: '/plans/group-travel-search',
      load: () => import(/* webpackChunkName: 'plans-group-travel-search' */ '../sites/plans/pages/groupTravelSearch'),
    },
    {
      path: '/plans/plan-overview/:id',
      load: () => import(/* webpackChunkName: 'plans-plan-overview' */ '../sites/plans/pages/planOverview'),
    },
    {
      path: '/plans/admin-plan-overview/:id',
      load: () => import(/* webpackChunkName: 'plans-admin-plan-overview' */ '../sites/plans/pages/adminPlanOverview'),
    },
    {
      path: '/plans/dashboard',
      load: () => import(/* webpackChunkName: 'plans-dashboard' */ '../sites/plans/pages/dashboard'),
    },
    {
      path: '/plans/plan-list',
      load: () => import(/* webpackChunkName: 'plans-plan-list' */ '../sites/plans/pages/planList'),
    },
    {
      path: '/plans/plan-list-v2',
      load: () => import(/* webpackChunkName: 'plans-plan-list-v2' */ '../sites/plans/pages/planListV2'),
    },
    {
      path: '/plans/plan-list-beta',
      load: () => import(/* webpackChunkName: 'plans-plan-list-beta' */ '../sites/plans/pages/planListBeta'),
    },
    {
      path: '/plans/my-itinerary/:id',
      load: () => import(/* webpackChunkName: 'plans-my-itinerary' */ '../sites/plans/pages/myItinerary'),
    },
    {
      path: '/plans/group-itinerary/:id',
      load: () => import(/* webpackChunkName: 'plans-group-itinerary' */ '../sites/plans/pages/groupItinerary'),
    },
    {
      path: '/plans/leader-group-itinerary/:id',
      load: () =>
        import(/* webpackChunkName: 'plans-leader-group-itinerary' */ '../sites/plans/pages/leaderGroupItinerary'),
    },
    {
      path: '/plans/transportation-details-dashboard',
      load: () =>
        import(
          /* webpackChunkName: 'transportation-details-dashboard' */ '../sites/plans/pages/transportationDetailsDashboard'
        ),
    },
    {
      path: '/plans/transportation-details-dashboard-beta',
      load: () =>
        import(
          /* webpackChunkName: 'transportation-details-dashboard-beta' */ '../sites/plans/pages/transportationDetailsDashboardBeta'
        ),
    },
    {
      path: '/plans/housing-details-dashboard',
      load: () =>
        import(/* webpackChunkName: 'housing-details-dashboard' */ '../sites/plans/pages/housingDetailsDashboard'),
    },
    {
      path: '/plans/housing-details-dashboard-beta',
      load: () =>
        import(
          /* webpackChunkName: 'housing-details-dashboard-beta' */ '../sites/plans/pages/housingDetailsDashboardBeta'
        ),
    },
    {
      path: '/plans/activity-details-dashboard',
      load: () =>
        import(/* webpackChunkName: 'activity-details-dashboard' */ '../sites/plans/pages/activityDetailsDashboard'),
    },
    {
      path: '/plans/activity-details-dashboard-beta',
      load: () =>
        import(
          /* webpackChunkName: 'activity-details-dashboard-beta' */ '../sites/plans/pages/activityDetailsDashboardBeta'
        ),
    },
    {
      path: '/plans/traveler-list',
      load: () => import(/* webpackChunkName: 'plans-traveler-list' */ '../sites/plans/pages/viaTravelTravelerList'),
    },
    {
      path: '/plans/traveler-list-beta',
      load: () =>
        import(/* webpackChunkName: 'plans-traveler-list-beta' */ '../sites/plans/pages/viaTravelTravelerListBeta'),
    },
    {
      path: '/safecheck/checkin/:id',
      load: () => import(/* webpackChunkName: 'safecheck-checkin' */ '../sites/safecheck/pages/checkin'),
    },
    {
      path: '/safecheck/messages',
      load: () => import(/* webpackChunkName: 'safecheck-messages' */ '../sites/safecheck/pages/messages'),
    },
    {
      path: '/safecheck/messages-beta',
      load: () => import(/* webpackChunkName: 'safecheck-messages-beta' */ '../sites/safecheck/pages/messagesBeta'),
    },
    {
      path: '/safecheck/messages/:id',
      load: () => import(/* webpackChunkName: 'safecheck-message-details' */ '../sites/safecheck/pages/messageDetails'),
    },
    {
      path: '/safecheck/phone',
      load: () => import(/* webpackChunkName: 'safecheck-phone' */ '../sites/safecheck/pages/safeCheckPhone'),
    },
    {
      path: '/reports/forms',
      load: () => import(/* webpackChunkName: 'reports-forms' */ '../sites/reports/pages/forms'),
    },
    {
      path: '/reports/forms-v2',
      load: () => import(/* webpackChunkName: 'reports-forms-v2' */ '../sites/reports/pages/forms-v2'),
    },
    {
      path: '/reports/risk-alert-history',
      load: () => import(/* webpackChunkName: 'risk-alert-history' */ '../sites/reports/pages/riskAlertHistory'),
    },

    {
      path: '/reports/downloads',
      load: () => import(/* webpackChunkName: 'risk-alert-history' */ '../sites/reports/pages/reportDownloads'),
    },

    {
      path: '/reports/applications',
      load: () => import(/* webpackChunkName: 'reports-applications' */ '../sites/reports/pages/applications'),
    },
    {
      path: '/reports/travelers',
      load: () => import(/* webpackChunkName: 'reports-travelers' */ '../sites/reports/pages/travelers'),
    },
    {
      path: '/reports/programs',
      load: () => import(/* webpackChunkName: 'reports-programs' */ '../sites/reports/pages/programs'),
    },
    {
      path: '/reports/historic-data',
      load: () => import(/* webpackChunkName: 'reports-horizons-brown' */ '../sites/reports/pages/horizonsBrown'),
    },
    {
      path: '/reports/sfsu-historic-data',
      load: () =>
        import(/* webpackChunkName: 'reports-sfsu-horizons-data' */ '../sites/reports/pages/sfsuHistoricData'),
    },
    {
      path: '/visitor-programs',
      action(context) {
        return {
          redirect: '/visitor-programs-v2',
        }; // <== request a redirect
      },
      // load: () => import(/* webpackChunkName: 'visitor-programs' */ '../sites/visitor/pages/visitorPrograms'),
    },
    {
      path: '/authV2/client-welcome',
      load: () =>
        import(
          /* webpackChunkName: 'authV2-client-welcome' */ '../sites/authentication/pages/client-select-path/ClientSelectPath.page'
        ),
    },
    // {
    //   path: '/authV2/started',
    //   load: () => import(/* webpackChunkName: 'authV2-started' */ '../sites/authentication/pages/started/Started.page'),
    // },
    {
      path: '/authV2/welcome',
      load: () => import(/* webpackChunkName: 'authV2-welcome' */ '../sites/authentication/pages/welcome/Welcome.page'),
    },
    {
      path: '/terms',
      load: () =>
        import(/* webpackChunkName: 'authV2-terms-of-use' */ '../sites/authentication/pages/terms-of-use/Term.page'),
    },
    {
      path: '/authV2/select-access',
      load: () =>
        import(
          /* webpackChunkName: 'authV2-select-access' */ '../sites/authentication/pages/select-access/SelectAccess.page'
        ),
    },
    {
      path: '/client/forms-dashboard',
      load: () =>
        import(
          /* webpackChunkName: 'form-dashboard' */ '../sites/forms/pages/admin/dashboard/FormsAdminDashboard.page'
        ),
    },
    {
      path: '/client/form-builder/:id',
      load: () => import(/* webpackChunkName: 'form-builder' */ '../sites/forms/pages/admin/builder/FormBuilder.page'),
    },
    {
      path: '/client/form-review/:id',
      load: () =>
        import(/* webpackChunkName: 'admin-form-review' */ '../sites/forms/pages/admin/review/AdminFormReview.page'),
    },
    {
      path: '/visitor/recommendation_submissions/:id',
      load: () =>
        import(
          /* webpackChunkName: 'visitor-recommendation-form' */ '../sites/forms/pages/visitor/recommendation/VisitorRecommendation.page'
        ),
    },
    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      path: '(.*)',
      load: () => import(/* webpackChunkName: 'errorpages/NotFound' */ './errorpages/NotFound'),
    },
  ],

  async action({ next }) {
    // Execute each child route until one of them return the result
    const route = await next();

    // Provide default values for title, description etc.
    route.title = `${route.title || 'Untitled Page'}`;
    route.description = route.description || '';

    return route;
  },
};

// The error pageCheckin is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    action: require('./error').default,
  });
}

export default routes;
