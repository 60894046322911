// Intake form
export const FETCH_INTAKE_FORM = 'FETCH_INTAKE_FORM';
export const ADD_INTAKE_FORM_GOAL = 'ADD_INTAKE_FORM_GOAL';
export const ADD_INTAKE_FORM_PROFILE = 'ADD_INTAKE_FORM_PROFILE';
export const ADD_INTAKE_FORM_ADVISING = 'ADD_INTAKE_FORM_ADVISING';
export const ADD_INTAKE_FORM_COMPLETE = 'ADD_INTAKE_FORM_COMPLETE';
export const ADD_INTAKE_FORM_PASSPORT = 'ADD_INTAKE_FORM_PASSPORT';
export const ADD_INTAKE_FORM_FINANCIAL_AID = 'ADD_INTAKE_FORM_FINANCIAL_AID';
export const ADD_INTAKE_FORM_TRAVELER_INFO = 'ADD_INTAKE_FORM_TRAVELER_INFO';
export const ADD_INTAKE_FORM_TRAVELER_TYPE = 'ADD_INTAKE_FORM_TRAVELER_TYPE';
export const ADD_INTAKE_FORM_FINANCIAL_INFO = 'ADD_INTAKE_FORM_FINANCIAL_INFO';
export const ADD_INTAKE_FORM_HOME_INSTITUTION = 'ADD_INTAKE_FORM_HOME_INSTITUTION';
export const ADD_INTAKE_FORM_TRAVEL_EXPERIENCE = 'ADD_INTAKE_FORM_TRAVEL_EXPERIENCE';
export const ADD_INTAKE_FORM_PARTICIPATION_CERTAINTY = 'ADD_INTAKE_FORM_PARTICIPATION_CERTAINTY';
export const ADD_INTAKE_FORM_COUNTRIES_OF_CITIZENSHIP = 'ADD_INTAKE_FORM_COUNTRIES_OF_CITIZENSHIP';

// Program match form
export const GET_PROGRAM_PREFERENCES = 'GET_PROGRAM_PREFERENCES';
export const ADD_PROGRAM_MATCH_FORM_COMPLETED = 'ADD_PROGRAM_MATCH_FORM_COMPLETED';
export const ADD_PROGRAM_MATCH_FORM_LANGUAGES = 'ADD_PROGRAM_MATCH_FORM_LANGUAGES';
export const ADD_PROGRAM_MATCH_FORM_PRIORITIES = 'ADD_PROGRAM_MATCH_FORM_PRIORITIES';
export const ADD_PROGRAM_MATCH_FORM_PROGRAM_TYPE = 'ADD_PROGRAM_MATCH_FORM_PROGRAM_TYPE';
export const ADD_PROGRAM_MATCH_FORM_PROGRAM_TERMS = 'ADD_PROGRAM_MATCH_FORM_PROGRAM_TERMS';
export const ADD_PROGRAM_MATCH_FORM_SUBJECT_AREAS = 'ADD_PROGRAM_MATCH_FORM_SUBJECT_AREAS';
export const ADD_PROGRAM_MATCH_FORM_PROGRAM_LENGTH = 'ADD_PROGRAM_MATCH_FORM_PROGRAM_LENGTH';
export const ADD_PROGRAM_MATCH_FORM_PROGRAM_COUNTRY = 'ADD_PROGRAM_MATCH_FORM_PROGRAM_COUNTRY';
export const ADD_PROGRAM_MATCH_FORM_PROGRAM_HOUSING = 'ADD_PROGRAM_MATCH_FORM_PROGRAM_HOUSING';
